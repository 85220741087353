import styled from 'styled-components'
import { Container, Row } from 'reactstrap'
import { Link } from 'react-router-dom'

export const OFFWHITE = '#faf9f6'

export const Header = styled.div`
    position: absolute;
    text-align: center;
    background: none;
    font-size: 36px;
    margin-top: 1rem;
    width: 100%;
    z-index: 2;

/* small iphones */
@media only screen and (width: 375px) { 
  font-size: 24px;
}



`
export const PhotoImg = styled.img`
  padding: 0;
  margin-top: 20vh;
  margin: 0;
  @media (min-width: 800px) {
    max-height: 850px;
  }
`
export const JoinText = styled.div`
    font-size: 26px;
    width: 88%;
    margin: auto;
    line-height: 114%;
    margin-top: 6rem;
    margin-bottom: 3rem;
    text-align: center;
    
  @media (min-width: 800px) {
    font-size: 26px;
    width: 43%;
    margin: auto;
    margin-top: 6rem;
    line-height: 110%;
    margin-bottom: 4rem;
    
  }
 


`

export const AppText = styled.div`
  font-size: 26px;
  width: 88%;
  margin-top: 2rem;
  margin-left: 1rem;
  line-height: 114%;
    
  @media (min-width: 800px) {
      font-size: 44px;
      width: 88%;
      margin-top: 2rem;
      margin-left: 2rem;
      line-height: 110%;
  }
`

export const ThankyouWrapper = styled.div`
    width: 90%;
    margin: auto;
    margin-top: 6rem;
    font-size: 22px;

    img {
        width: 100%;
        margin-bottom: 2rem;
        }
`
export const FormWrapper = styled.div`
width: 90%;
    margin: auto;
    
  input {   
    border: none;
    border-bottom: 1px solid black;
    border-radius: 0;
    background: none;
    font-size: 18px;
    }


    @media (min-width: 800px) {
    width: 50%;
    max-width: 100%;
    margin-bottom: 6rem;
  }

    `
export const Cookies = styled.div`
    position: absolute;
    /*width: 100%; */
    height: 2rem;
    color: white;
    background: black;
    bottom: 3rem;
    padding: 0.5rem;
    min-height: 3rem;
    bottom: 0;
    opacity: 0.5;
    display: none;
  `
//https://stackoverflow.com/questions/63501825/position-fixed-button-turned-90-degree-fixed-to-the-right-side-of-the-screen
export const VerticalText = styled.div`
  
 
  transform: translateX(160%) rotate(-90deg) translateX(100%);
  color: red;
  height: 0;
  width: 280px !important;
  font-style: italic;
  font-size: 18px;
  position: absolute;
  top: 10vh !important;


  @media (min-width: 700px) {
    font-size: 14px;
    width: 30% !important;
    top: 30vh !important;
    color: red !important;

  }



  @media (min-width: 800px) {
    /* desktop */
    font-size: 34px;
    top: 600px !important;
    width: 45% !important;
    vertical-align: middle;
  }


  
  @media (max-width: 600px) {
    font-size: 14px;
    width: 43% !important;
    top: 30vh !important;
    color: black !important;

  }

`

/*
old


  transform: translateX(160%) rotate(-90deg) translateX(100%);
  color: black;
  height: 20px;
  width: 170px;
  font-style: italic;
  font-size: 18px;

*/


export const FullContainer = styled(Container)`
  background: ${OFFWHITE};
  margin: 0;
  padding: 0;
  
`


export const BodyRow = styled(Row)`
  margin: 0;
  padding: 0;
  position: relative; 
  margin: auto;
  @media (min-width: 800px) {
    width: 100%;
    max-width: 100%;
    overflow-x: clip;
    margin: auto;
  }
`

export const ImpressumRow = styled(Row)`
text-align: right;
margin: 0;
padding: 0;
border-top: 1px solid black;
margin-top: 1rem;
padding-top: 0.5rem;
padding-bottom: 32px;
padding-right: 1rem;
`



export const StyledLink = styled(Link)`
  
  color: black !important;
  text-decoration: none !important;

  &:active { color: black !important;
  
   }
  &:hover {
    font-weight: normal !important;
    font-style: italic !important;
  }
  /*
  &:visited {
    font-weight: normal !important;
    font-style: italic !important;
    color: #5F5F5F !important;
  }
  */

  
  
`



export const ImpressumLink = styled(Link)`
    color: black;
    text-decoration: none;
    font-size: 22px;


  color: black !important;
  text-decoration: none !important;

  &:active { color: black !important;
  
   }
  &:hover {
    font-weight: normal !important;
    font-style: italic !important;
  }
  
  `


  export const UponLegalization = styled.div`
    font-style: italic;
    width: 100%;
    text-align: left;
    margin-left: 0.5rem;


  @media (min-width: 800px) { 
    font-size: 26px;
    margin-left: 2rem;
  }

  `