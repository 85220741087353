import { Cookies, ImpressumLink, ImpressumRow, UponLegalization } from "./styles";

function Footer() {

  const date = new Date().toJSON().substring(0,4);

  return (
    <>
    <ImpressumRow>
      <UponLegalization>*upon legalisation</UponLegalization>
          <ImpressumLink to='/impressum' >{date}</ImpressumLink>
        </ImpressumRow>
        <Cookies>COOKIES</Cookies>
        </>  
  );
}

export default Footer;