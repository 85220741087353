import jsonp from 'jsonp';
import styled from 'styled-components';
import { useState } from 'react';
import { Button, Form, FormGroup, Input, Row } from 'reactstrap'
import ReactGA from "react-ga4";
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'


//import mobile from './assets/thankyou_mobile.jpg'
//import desktop from './assets/thankyou_desktop.jpg'
//import { isMobileOnly } from 'react-device-detect';
import { FormWrapper, ThankyouWrapper, JoinText, FullContainer, BodyRow, StyledLink, Header } from './components/styles';
import Footer from './components/Footer';


// https://www.thisdot.co/blog/how-to-integrate-mailchimp-forms-in-a-react-project/
// from mailchimp
const POST_URL = 'https://gmail.us14.list-manage.com/subscribe/post-json?u=2a7dbbe2b56c524d57f26e7e8&amp;id=c61e4d5396&amp;f_id=00019ae0f0'
// const HCF_GREEN = '#219653'


ReactGA.initialize("G-R29G9J4HLN");

function Join() {

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    jsonp(`${POST_URL}&EMAIL=${email}&FNAME=${firstName}&LNAME=${lastName}`, { param: 'c' }, (_, data) => {
      console.log('data', data);
      const { result } = data;
      if (result === 'success') setSubmitSuccess(true);
    });
  };

  const renderForm = () => <FormWrapper>
    <Form onSubmit={onSubmit}>
      <FormGroup>
        <Input placeholder="First Name" id="fname" name="FNAME" required onChange={(e) => setFirstName(e.target.value)} />
      </FormGroup>
      <FormGroup>
        <Input placeholder="Last Name" id="lname" name="LNAME" required onChange={(e) => setLastName(e.target.value)} />
      </FormGroup>
      <FormGroup>
        <Input placeholder="Email" id="email" name="EMAIL" type="email" required onChange={(e) => setEmail(e.target.value)} />
      </FormGroup>
      <SubmitButton outline block type="submit">Submit</SubmitButton>
    </Form>
  </FormWrapper>

  const renderThankyou = () =>
    <ThankyouWrapper>
      Thank you for signing up. Check your email for updates.</ThankyouWrapper>


  return (
    <div className="App">
      <FullContainer fluid>
        <Header>
          <StyledLink to='/'>Handcut Flowers</StyledLink>
        </Header>
        <Row className='m-0 p-0'>
        </Row>
        {!submitSuccess ?
          <BodyRow className='mt-3 text-align-center'>
            <JoinText><em>Subscribe now, limited memberships available</em></JoinText>
          </BodyRow> : null}
        <Row className='m-0 p-0' >{submitSuccess ? renderThankyou() : renderForm()}</Row>

        <Footer />
      </FullContainer>
    </div>
  );
}

export default Join;

const SubmitButton = styled(Button)`
    color: black;
    font-family: sans-serif;
    text-decoration: underline;
    margin-top: 1.65rem;
    border: none;
    font-size: 18px;
    color: black!important;
    text-decoration: underline!important;
    background: none!important;
    text-align: right;
    margin-right: 1rem;
    
    padding-right: 1rem;
    width: 100% !important;

  &:active { color: black !important; }
  &:hover {
    font-weight: normal !important;
    font-style: italic !important;
  }
  &:visited {
    font-weight: normal !important;
    
    color: #5F5F5F !important;
  }

  @media (min-width: 800px) { 
    margin-top: 2rem;
    font-size: 26px;
    margin-left: 2rem;
  }
`



//const PhotoImg = styled.img`
//  padding: 0;
//  margin-top: 20vh;
//  margin: 0;
//  @media (min-width: 800px) {
//    max-height: 850px;
//  }
//`
