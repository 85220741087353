import styled from 'styled-components'
import { Button, Row } from 'reactstrap'
import ReactGA from "react-ga4";
import { isMobileOnly } from 'react-device-detect'
import { PhotoImg, Header, FullContainer, AppText, BodyRow, StyledLink } from './components/styles';
import CookieConsent, { Cookies } from "react-cookie-consent";

import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'

import mobile from './assets/mobile.jpg'
import desktop from './assets/desktop.jpg'
import Footer from './components/Footer';

// cookies as per: https://dev.to/ramonak/react-enable-google-analytics-after-a-user-grants-consent-5bg3
//  is an emerging cannabis club offering singular strains, a budding community, and health and wellness focused offerings. *
function App() {

  return (
    <div className="App">
      <FullContainer fluid>
        <Header>Handcut Flowers</Header>
        <JoinButton>
          <StyledLink to='/join'>Join</StyledLink>
        </JoinButton>
        <Row className='m-0 p-0'>
          <PhotoImg src={isMobileOnly ? mobile : desktop} />
        </Row>
        <BodyRow>
          <AppText><em>Handcut Flowers</em> is a Cannabis Social Club in Berlin that will offer a wide variety of top quality, hand selected strains and a budding community centered around wellness, education, and research.*<br/><br/>Please join our mailing list to secure your spot in Berlin's soon-to-be premiere CSC.</AppText>
          <SignUpButton>
            <StyledLink to='/join'>Sign Up</StyledLink>
          </SignUpButton>
        </BodyRow>
        <CookieConsent

onAccept={() => {
  ReactGA.initialize("G-R29G9J4HLN");

    
  }}
          enableDeclineButton
          declineButtonText="No"
          buttonText="Yes"
          onDecline={() => {

  //remove google analytics cookies
  Cookies.remove("_ga");
  Cookies.remove("_gat");
  Cookies.remove("_gid");

          }}
          style={{ background: "black" }}
  buttonStyle={{ color: "black", background: 'white', fontSize: "13px" }}
        
        ><div class="cookiePrompt">Cookies ?</div></CookieConsent>

        <Footer />
      </FullContainer>
    </div>
  );
}



export default App;


const SignUpButton = styled(Button)`
  right: 1rem;
  color: black;
  font-family: sans-serif;
  text-decoration: underline;
  margin-top: 1.65rem;
  border: none;
  font-size: 18px;
  color: black !important;
  text-decoration: underline !important;
  background: none !important;
  text-align: left;
  margin-left: 1rem;
  width: 80%;

  &:active { color: black !important; }
  &:hover {
    font-weight: normal !important;
    font-style: italic !important;
  }
  &:visited {
    font-weight: normal !important;
    font-style: italic !important;
    color: #5F5F5F !important;
  }

  @media (min-width: 800px) { 
    margin-top: 2rem;
    font-size: 26px;
    margin-left: 2rem;
  }
`

const JoinButton = styled(Button)`
  position: absolute;
  right: 1rem;
  color: black;
  font-family: sans-serif;
  text-decoration: underline;
  margin-top: 1.65rem;
  border: none;
  font-size: 18px;
  color: black !important;
  text-decoration: underline !important;
  background: none !important;
  z-index: 2;
  &:active { color: black !important; }
  &:hover {
    font-weight: normal !important;
    font-style: italic !important;
  }
  &:visited {
    font-weight: normal !important;
    font-style: italic !important;
    color: #5F5F5F !important;
  }
  
  @media (min-width: 800px) { 
    margin-top: 0.65rem;
    font-size: 26px;
  }

/* small iphones */
@media only screen and (width: 375px) { 
    margin-top: 0.8rem;
    font-size: 19px;
}


`
