import { Header, FullContainer, BodyRow, StyledLink, JoinText } from './components/styles';

import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'

import Footer from './components/Footer';



function Impressum() {

  return (
    <div className="App">
      <FullContainer fluid>
      <Header>
          <StyledLink to='/'>Handcut flowers</StyledLink>
        </Header>        
        <BodyRow>
          <JoinText>Angaben gem&auml;&szlig; &sect; 5 TMG<br /><br />
        Handcut Flowers<br />
        Schoenhauser Allee 54<br />
        10437 Berlin<br /><br />
        
        <p><em>Verein in Gründung, Eintragung beim Registergericht anhängig.</em></p>

        <br />
        Kontakt<br />
        Telefon: +49 (0) 159 01828 041<br /><br />
        E-Mail: info@handcutflowers.club<br />
        <br />
        Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle<br /><br />
        Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
        <br /><br />
        Quelle: <a rel="noreferrer" target={'_blank'} href="https://www.e-recht24.de">eRecht24</a>
        </JoinText>
        </BodyRow>
        <Footer />
      </FullContainer>
    </div>
  );
}

export default Impressum;

